import React, { useCallback, useContext, useMemo, useState } from 'react';
import { get } from 'lodash';
import { decode } from "he";
import AuthContext from '../../../context/AuthProvider';
import { hasStaffPermissions, persistLocation } from '../../../helpers/general';
import AccountPageWrapper from '../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import ClubApplication from '../../../components/organisms/ClubApplication/ClubApplication';
import ClubCompliant from '../../../components/organisms/ClubCompliant/ClubCompliant';
// import ClubConfirm from '../../../components/organisms/ClubConfirm/ClubConfirm';
import ClubDetails from '../../../components/organisms/ClubDetails/ClubDetails';
import ClubGM from '../../../components/organisms/ClubGM/ClubGM';
import { wpApi } from '../../../helpers/wordpress';

const Preview = ({ location }) => {
  const auth = useContext(AuthContext);
  const [authed, setAuthed] = useState(null);
  const { state } = location;

  const [selectedClub, setSelectedClub] = useState(null);
  const [viewType, setViewType] = useState(null);
  // const [voteCount, setVoteCount] = useState(0);
  // const [voteResults, setVoteResults] = useState(null);
  const [GMHistory, setGMHistory] = useState(null);
  const [switchView, setSwitchView] = useState('gm');

  useMemo(() => {
    if (authed === null && get(auth, 'state.userChecked')) {
      setAuthed(hasStaffPermissions(auth, [2, 3, 4]));
    }
  }, [auth, authed, setAuthed]);

  const fetchClubList = useCallback(async () => {
    const clubs = await wpApi(`clubs/${location.state.clubId}`);

    if (clubs.status === 200 && clubs.response) {
      setSelectedClub(clubs.response);
      setViewType(clubs.response.status);
      // console.log(clubs.response);
      // if (clubs.response.status === 'engaged') {
      //   const votes = await wpApi(`ign_votes?club=${location.state.clubId}`);
      //   // console.log("votes: ", votes);
      //   if (String(votes.status).startsWith('2')) {
      //     const results = {};
      //     votes.response.map(v => {
      //       const memberVotes = JSON.parse(v.acf.votes);
      //       memberVotes.map(mv => {
      //         if (mv.vote !== null) {
      //           const role = mv.role.split(' (#')[0];
      //           if (!(role in results)) {
      //             results[role] = [];
      //             results[role].push({...mv.vote, count: 1});
      //           } else {
      //             const votedMemberIndex = results[role].findIndex(vm => vm.member_number === mv.vote.member_number);
      //             if (votedMemberIndex > -1) {
      //               results[role][votedMemberIndex].count += 1;
      //             } else {
      //               results[role].push({...mv.vote, count: 1});
      //             }
      //           }
      //         }

      //         return true;
      //       })

      //       return true;
      //     });

      //     // console.log(results);

      //     setVoteCount(votes.response.length);
      //     setVoteResults(results);
      //   } else {
      //     // Failed fetch of event data
      //     setVoteResults(false);
      //   }
      // } else 
      if (['engaged', 'publish'].indexOf(clubs.response.status) > -1 && clubs.response.acf.current_igmagm_history_record && clubs.response.acf.current_igmagm_history_record !== null) {
        setViewType(clubs.response.status === 'publish' ? 'gm' : clubs.response.status);
        const history = await wpApi(`igm_agm_history/${clubs.response.acf.current_igmagm_history_record.ID}`);
        // console.log("history: ", history);
        if (String(history.status).startsWith('2')) { //  && history.response.acf.status === 'pending'
          setGMHistory(history.response);
        } else {
          // Failed fetch data
          
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    if ((!location.state || !('clubId' in location.state)) && typeof window !== 'undefined') window.location = '/account/manage-clubs/';
    if (selectedClub === null) {
      fetchClubList();
    }
  }, [selectedClub, fetchClubList]);

  return (
    <div>
      {(selectedClub && (viewType === 'applied' || viewType === 'signatures')) && (
        <ClubApplication club={selectedClub} forApproval={state.forApproval} />
      )}

      {(selectedClub && viewType === 'compliant') && (
        <ClubCompliant club={selectedClub} auth={get(auth, 'state', {})} forApproval={state.forApproval} />
      )}

      {(selectedClub && viewType === 'engaged' && GMHistory) && (
        // <ClubConfirm club={selectedClub} forApproval={state.forApproval} voteCount={voteCount} voteResults={voteResults} />
        <ClubGM club={selectedClub} auth={get(auth, 'state', {})} forApproval={state.forApproval} history={GMHistory} />
      )}

      {(selectedClub && viewType === 'publish') && (
        <ClubDetails club={selectedClub} forApproval={state.forApproval} />
      )}

      {(selectedClub && viewType === 'gm' && GMHistory) && (
        <>
          {switchView === 'gm' && (
            <>
              <a href='#' onClick={(e) => {e.preventDefault(); setSwitchView('details');}} style={{display: 'block', marginBottom: '30px'}}>Switch to Club Details view</a>
              <ClubGM club={selectedClub} auth={get(auth, 'state', {})} forApproval={state.forApproval} history={GMHistory} />
            </>
          )}
          {switchView === 'details' && (
            <>
              <a href='#' onClick={(e) => {e.preventDefault(); setSwitchView('gm');}} style={{display: 'block', marginBottom: '30px'}}>Switch to Club GM view</a>
              <ClubDetails club={selectedClub} forApproval={state.forApproval} />
            </>
          )}
        </>
      )}
    </div>
  );
};

const USUClubManagementPreviewOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - USU Management'
      title={`Manage ${decode(location?.state?.clubName || 'Club')}`}
      bgRaw
      breadcrumbTitle='Manage Clubs'>
      <Preview location={persistedLocation} />
    </AccountPageWrapper>
  )
};

export default USUClubManagementPreviewOutput;

