import { Link } from 'gatsby'
import React from 'react'
import { isPastDate, transformToEventDate } from '../../../helpers/general'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import Placeholder from '../Placeholder/Placeholder'
import * as styles from './MyUsu.module.css'
import { decode } from 'he'
import moment from 'moment'

const MyUsuEvent = ({event, onLeave, onSignIn, hasLink = false, isSmall = false}) => {
  const dates = transformToEventDate(event.acf.start_date, event.acf.start_time, event.acf.end_date, event.acf.end_time, true);

  const isPast = () => {
    if (event.acf.end_date) {
      return isPastDate(`${event.acf.end_date.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')} ${event.acf.end_time}`, true);
    }
    
    return false;
  }

  return (
    <div className={`${styles.card} ${isSmall && styles.smallCard}`}>
      <div className={styles.content}>
        {hasLink && <Link  className={styles.overlay} to={`/events/${event.slug}`}></Link>}
        {event.acf.logo ? <img src={event.acf.logo} alt="" />
          : <Placeholder isSmall={isSmall} />
        }
        <div className={`${styles.eventDetails} ${isSmall && styles.smallDetails}`}>
          <h1 className="title">{decode(event.acf.full_title || event.title.rendered)}</h1>
          <p className="excerpt">
          <Icon symbol={'arrowDownAlt'}></Icon>
            {dates.join(' - ')}
          </p>
        </div>
      </div>
      {(onLeave || onSignIn) && 
        <div className={styles.actions}>
          {onSignIn && !isPast() && (<Button level="primary" size="tinier" theme="grey" type="span" onClick={() => onSignIn(event.id)}>Check in</Button>)}
          {onLeave && (<Button level="secondary" size="tinier" theme="grey" type="span" onClick={() => onLeave(event.slug)}>Leave</Button>)}
        </div>
      }
    </div>
  )
}

export default MyUsuEvent