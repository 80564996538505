// import { Link } from "gatsby";
import React, { useCallback, useContext, useMemo, useState } from "react";
import Dialog from "../../../components/atoms/Dialog/Dialog";
import MyUsuEvent from "../../../components/atoms/MyUsu/MyUsuEvent";
import AccountPageWrapper from "../../../components/organisms/AccountPageWrapper/AccountPageWrapper";
import MyUsuTab from "../../../components/organisms/MyUsuTab/MyUsuTab";
import AuthContext from "../../../context/AuthProvider";
import { generateQR, isPastDate } from "../../../helpers/general";
import { getPerson, updatePerson } from "../../../helpers/ortto";
import { wpApi } from "../../../helpers/wordpress";

import * as styles from './usu.module.css';

const Events = () => {

    const auth = useContext(AuthContext);
    const userEmail = auth && auth.state.email;
    const memberNumber = auth && auth.state.usu.MemberNumber;
    const [eventList, setEventList] = useState(null);
    const [eventHistoryList, setEventHistoryList] = useState(null);
    const [qrCode, setQrCode] = useState(false);

    const fetchEventList = useCallback(async () => {
        const result = await getPerson(['Tags'], userEmail);
        if (result.status === 200 && "contacts" in result.response && Array.isArray(result.response.contacts)) {
            // Successfully found
            if (result.response.contacts[0].fields.tags) {
                const eventSlugs = result.response.contacts[0].fields.tags.filter(tag => tag.indexOf('event-') === 0).map(tag => tag.replace('event-', ''));
                if (eventSlugs.length > 0) {

                    if (memberNumber === '2351174') {
                        eventSlugs.push([
                            ...eventSlugs,
                            'week-1-discussion',
                            'psychsoc-speed-friending'
                        ])
                    }
                    const events = await wpApi(`events?slug=${eventSlugs.join(',')}`);
                    if (events.status === 200 && events.response.length > 0) {
                        const history = [];
                        setEventList(events.response.filter((event) => {
                            if (event.acf.end_date && isPastDate(`${event.acf.end_date.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3')} ${event.acf.end_time}`, true)) {
                                history.push(event);
                                return false;
                            }

                            return true;
                        }));
                        setEventHistoryList(history);
                    } else {
                        // Failed fetch of event data
                        setEventList(false);
                    }
                } else {
                    // No event tags found
                    setEventList(false);
                }
            } else {
                // No tags found
                setEventList(false);
            }
        } else {
            // Failed fetch of tags
            setEventList(false);
        }
    }, [userEmail]);

    useMemo(() => {
        if (eventList === null) {
            fetchEventList();
        }
    }, [eventList, fetchEventList]);

    const leaveEvent = async (slug) => {
        const eventKey = `event-${slug}`;
        const userKey = userEmail;

        const person = [
            {
                email: userKey,
                unset_tags: [eventKey],
            }
        ];
        const updateResponse = await updatePerson(person);

        if (updateResponse.status === 200 && "people" in updateResponse.response) {
            // Successfully added
            fetchEventList();
        } else {
            // Error occurred
            // TODO: Show error somewhere
        }
    }

    const signInEvent = async (eventId) => {
        const theCode = await generateQR({ memberId: memberNumber, eventId });
        setQrCode(theCode);
    }

    const [activeView, setActiveView] = useState('upcoming');
    const triggerViewChange = (view) => {
        setActiveView(view)
    }

    return (
        <div className={styles.root}>
            {/* List events */}
            <p>Here is the list of all events you have joined:</p>
            <div>
                <span className={`${styles.tab}${activeView === 'upcoming' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('upcoming')}>Upcoming Events</span>
                <span className={`${styles.tab}${activeView === 'history' ? ` ${styles.active}` : ''}`} role="presentation" onClick={() => triggerViewChange('history')}>Past Events</span>
            </div>
            <>
                {(eventList && Array.isArray(eventList) && eventHistoryList && Array.isArray(eventHistoryList)) ? (
                    activeView === 'upcoming' ? (
                        <div >
                            {eventList.map((event, eventIndex) => (
                                <MyUsuEvent key={eventIndex} event={event} onLeave={leaveEvent} onSignIn={signInEvent} hasLink />
                            ))}
                            {eventList.length < 1 && (
                                // https://matterdesign.atlassian.net/browse/USU-496
                                <div>You haven no upcoming events</div>
                            )}
                        </div>
                    ) : (
                        <div >
                            {eventHistoryList.map((event, eventIndex) => (
                                <MyUsuEvent key={eventIndex} event={event} onLeave={leaveEvent} onSignIn={signInEvent} hasLink />
                            ))}
                            {eventHistoryList.length < 1 && (
                                // https://matterdesign.atlassian.net/browse/USU-496
                                <div>You haven no record of past events</div>
                            )}
                        </div>
                    )
                ) : (
                    <div>
                        {/* Fetching events */}
                        {eventList === null && (
                            <div>Fetching your events</div>
                        )}

                        {/* No events found */}
                        {eventList === false && (
                            // https://matterdesign.atlassian.net/browse/USU-496
                            <div>You have not registered for any <a target='_blank' rel='noopener noreferrer' href="/events"><u>events</u></a></div>
                        )}
                    </div>
                )}
            </>
            <Dialog open={qrCode} size="xs" onOk={() => setQrCode(false)} hideBtnCancel hideBtnClose><div style={{ textAlign: 'center' }}><img src={qrCode} alt="QR Code" style={{ width: '100%' }} /></div></Dialog>
        </div>
    )
}

const EventsOutput = () => (
    <AccountPageWrapper metaTitle="Account - My USU" breadcrumbTitle={'Events'} title="Welcome to my USU Portal">
        <MyUsuTab pageId={'events'}>
            <Events />
        </MyUsuTab>
    </AccountPageWrapper>

)

export default EventsOutput