import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { get } from 'lodash';
import { decode } from 'he';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import Button from '../../../../../components/atoms/Button/Button';
import Icon from '../../../../../components/atoms/Icon/Icon';
import MessageAlert from '../../../../../components/atoms/MessageAlert/MessageAlert';
import InformationBar from '../../../../../components/molecules/InformationBar/InformationBar';
import AccountPageWrapper from '../../../../../components/organisms/AccountPageWrapper/AccountPageWrapper';
import AuthContext from '../../../../../context/AuthProvider';
import { ucFirst, persistLocation, getGrantValue } from '../../../../../helpers/general';
import { getPerson } from '../../../../../helpers/ortto';
import { authClubExec, wpApi, wpAll } from '../../../../../helpers/wordpress';

import * as styles from '../../usu.module.css';
import CurrencyFormatter from '../../../../../components/atoms/CurrencyFormatter/CurrencyFormatter';

const Manage = ({ location }) => {
  const auth = useContext(AuthContext);
  const memberNumber = auth && get(auth, 'state.usu.MemberNumber');
  const memberDetails = auth && get(auth, 'state');
  const userEmail = auth && get(auth, 'state.email');
  const [isAuthed, setAuthed] = useState(null);
  const [club, setClub] = useState(null);
  const [memberList, setMemberList] = useState(null);
  const [eventList, setEventList] = useState(null);
  const [historyList, setHistoryList] = useState(null);
  const [clubNotifications, setClubNotifications] = useState([]);
  // const [voteResults, setVoteResults] = useState(null);
  const [voteCount, setVoteCount] = useState(0);
  const [historyRecord, setHistoryRecord] = useState(null);
  const [nominationsCount, setNominationsCount] = useState(0);
  const [execDetails, setExecDetails] = useState({});

  const { allWpClubTag, wp: {acfOptionsGeneral: {clubFundTiers: grantTiers} } } = useStaticQuery(graphql`
    query {
      allWpClubTag {
        nodes {
          databaseId
          name
        }
      }

      wp {
        acfOptionsGeneral {
          clubFundTiers {
            clubFundingTiers {
              value
              min
              max
            }
          }
        }
      }
    }
  `);

  const fetchEventList = useCallback(async () => {
    if (get(location, 'state.clubId')) {
        const events = await wpAll(`events?status=draft,publish&club=${location.state.clubId}&upcoming`);
        if (events.status === 200) {
            setEventList(events.response);
        } else {
            // Failed fetch of event data
            setEventList(false);
        }
    }
  }, [location]);

  const fetchVoteResults = useCallback(async () => {
    if (get(location, 'state.clubId')) {
      const votes = await wpAll(`ign_votes?club=${location.state.clubId}`);
      if (String(votes.status).startsWith('2')) {
        const results = {};
        votes.response.map(v => {
          const memberVotes = JSON.parse(v.acf.votes);
          memberVotes.map(mv => {
            if (mv.vote !== null) {
              const role = mv.role.split(' (#')[0];
              if (!(role in results)) {
                results[role] = [];
                results[role].push({ ...mv.vote, count: 1 });
              } else {
                const votedMemberIndex = results[role].findIndex(
                  vm => vm.member_number === mv.vote.member_number
                );
                if (votedMemberIndex > -1) {
                  results[role][votedMemberIndex].count += 1;
                } else {
                  results[role].push({ ...mv.vote, count: 1 });
                }
              }
            }

            return true;
          });

          return true;
        });

        // console.log(results);

        setVoteCount(votes.response.length);
        // setVoteResults(results);
      } else {
        // Failed fetch of event data
        // setVoteResults(false);
      }
    }
  }, [location]);

  const fetchHistoryList = useCallback(async () => {
    if (get(location, 'state.clubId')) {
      const history = await wpAll(`igm_agm_history?club=${location.state.clubId}`);
      if (String(history.status).startsWith('2')) {
        const filteredHistory = history.response.map(h => h.acf.event ? h : false).filter(h => h);
        setHistoryList(filteredHistory);
      } else {
        // Failed fetch of history data
        setHistoryList(false);
      }
    }
  }, [location]);

  const fetchGMData = useCallback(async record => {
    if (record) {
      const history = await wpApi(`igm_agm_history/${record.ID}`);
      if (String(history.status).startsWith('2')) {
        setHistoryRecord(history.response);
        if (history.response.acf.event.post_status === 'publish') {
          const nominations = await wpAll(
            `igm_agm_nominations?event=${history.response.acf.event.ID}`
          );
          if (
            String(nominations.status).startsWith('2') &&
            nominations.response.length > 0
          ) {
            setNominationsCount(nominations.response.length);
          }
        }
      } else {
        // Failed fetch of event data
        setHistoryRecord(false);
      }
    }
  }, []);

  const fetchClubMembers = useCallback(async () => {
    const result = await getPerson(['Tags', 'u4s::t'], userEmail);
    if (
      result.status === 200 &&
      'contacts' in result.response &&
      Array.isArray(result.response.contacts)
    ) {
      // Successfully found
      if (result.response.contacts[0].fields.tags && result.response.contacts[0].fields['u4s::t']) {
        const tagIndex = result.response.contacts[0].fields.tags.indexOf(
          `club-${location.state.clubSlug}`
        );
        if (tagIndex > -1) {
          const _memberList = await getPerson(
            ['first', 'last', 'email'],
            result.response.contacts[0].fields['u4s::t'][tagIndex],
            'tag_id'
          );

          if (
            _memberList.status === 200 &&
            'contacts' in _memberList.response &&
            Array.isArray(_memberList.response.contacts)
          ) {
            setMemberList(
              _memberList.response.contacts.map(member => {
                const name = `${member.fields['str::first'] || ''} ${member
                  .fields['str::last'] || ''}`.trim();
                return {
                  id: member.id,
                  member_name: name || '(No name set)',
                  email: member.fields['str::email'],
                };
              })
            );
          }
        }
      }
    }
  }, [userEmail, location]);

  const fetchClubNotifications = useCallback(async () => {
    const notifications = await wpAll('pages?highlighted=true');
    if (String(notifications.status).startsWith('2')) {
      const _notifications = notifications.response.map(n => ({
        modalDetail: true,
        text: n.title.rendered,
        content: n.content.rendered,
      }));
      setClubNotifications(_notifications);
    }
  }, []);

  const fetchPageData = useCallback(() => {
    if (get(location, 'state.clubId')) {
      authClubExec(memberNumber, get(location, 'state.clubId')).then(authed => {
        if (String(authed.status).startsWith('2') && authed.response) {
          setAuthed(true);
          const execDetail = authed.response.acf.club_execs.find(e => e.member_number === memberNumber);
          setExecDetails(execDetail);
          fetchClubMembers();
          fetchEventList();
          fetchHistoryList();
          fetchClubNotifications();
          if (authed.response.status === 'ign') {
            fetchVoteResults();
          }
          if (authed.response.acf.current_igmagm_history_record) {
            fetchGMData(authed.response.acf.current_igmagm_history_record);
          }
          setClub(authed.response);
        } else {
          setAuthed(false);
        }
      });
    }
  }, [memberNumber, location, fetchClubMembers, fetchEventList, fetchHistoryList, fetchClubNotifications, fetchVoteResults, fetchGMData]);

  useMemo(() => {
    // console.log("Detect change", location.state.clubId, club?.id);
    if (isAuthed === null) {
      fetchPageData();
    } else if (isAuthed && club && get(location, 'state.clubId') !== club.id) {
      setMemberList(null);
      setEventList(null);
      setHistoryList(null);
      fetchPageData();
    }
  }, [isAuthed, location, club, fetchPageData]);

  const linkToManagementPage = (managePath, extra) => {
    navigate(`/account/my-usu/clubs/manage/${managePath}`, {
      state: {
        clubSlug: club.slug,
        clubId: club.id,
        clubTitle: club.title.rendered,
        clubStatus: club.status,
        user: memberDetails.usu,
        ...extra,
      },
    });
  };

  const linkToPageNewTab = url => {
    if (typeof window !== undefined) {
      window.open(url, '_blank').focus();
    }
  };

  const formatDate = (date, addDays) => {
    const _date = new Date(date);
    _date.setDate(_date.getDate() + Number(addDays));
    const options = {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Australia/Sydney',
    };
    return new Intl.DateTimeFormat('default', options).format(_date);
  };

  const tags = useMemo(() => {
    const tags = [];
    if (club && allWpClubTag) {
      club.club_tags.forEach(tag => {
        const findTag = allWpClubTag?.nodes.find(
          node => node.databaseId === tag
        );
        if (findTag) tags.push(findTag.name);
      });
    }
    return tags;
  }, [club, allWpClubTag]);

  const grantValue = useMemo(() => {
    if (grantTiers && memberList) {
      // const memberCount = memberList.length;
      // const tier = grantTiers.find(t => t.min <= memberCount && t.max >= memberCount);
      // return tier ? tier.value : false;
      return getGrantValue('semester 1', club, memberList, grantTiers, {});
    }

    return false;
  }, [grantTiers, memberList])

  return (
    <>
      {club ? (
        <>
          <div className={`${styles.root} ${styles.withBg}`}>
            {/* Club Dashboard */}

            {/* AGM notifications */}
            {/* AGM Step 1 */}
            {/* {console.log(club, get(club, 'acf.agm_functionality', false), get(club, 'acf.current_igmagm_history_record', false) === false)} */}
            {get(club, 'acf.agm_functionality', false) &&
              get(club, 'acf.current_igmagm_history_record', false) ===
                false && (
                <MessageAlert
                  type='warn'
                  message={
                    <div className={styles.clubAlert}>
                      <div>
                        Club is due to hold its{' '}
                        {club.status !== 'publish' ? 'IGM' : 'AGM'} in the month
                        of {ucFirst(club.acf.agm_month)}.<br />
                        Start setting up for this now!
                      </div>

                      <div>
                        <Button
                          level='primary'
                          size='tiny'
                          type='button'
                          onClick={() =>
                            linkToManagementPage('event-submission', {
                              special: 'agm',
                            })
                          }>
                          Get started
                        </Button>
                      </div>
                    </div>
                  }
                />
              )}

            {/* AGM Step 2 */}
            {/* {console.log(club, get(club, 'acf.agm_functionality', false), historyRecord, historyRecord.acf.event.post_status === "draft")} */}
            {get(club, 'acf.agm_functionality', false) &&
              historyRecord &&
              historyRecord.acf.event.post_status === 'draft' && (
                <MessageAlert
                  type='warn'
                  message={
                    <div className={styles.clubAlert}>
                      <div>
                        Your {club.status !== 'publish' ? 'IGM' : 'AGM'} is
                        being reviewed by staff. Once approved, executive
                        nominations will be open to members.
                      </div>
                    </div>
                  }
                />
              )}

            {/* AGM Step 3 */}
            {/* {console.log(club, get(club, 'acf.agm_functionality', false), historyRecord)} */}
            {/* {console.log(club, get(club, 'acf.agm_functionality', false), historyRecord, historyRecord?.acf.event.post_status === "publish", historyRecord?.acf.status === 'running', (historyRecord?.acf.returning_officer === '' || (historyRecord && !('returning_officer' in historyRecord?.acf))))} */}
            {get(club, 'acf.agm_functionality', false) &&
            historyRecord &&
            historyRecord?.acf.event.post_status === 'publish' &&
            historyRecord?.acf.status === 'running' &&
            (historyRecord?.acf.returning_officer === '' ||
              (historyRecord &&
                !('returning_officer' in historyRecord?.acf))) && ( // `
                <MessageAlert
                  type='warn'
                  message={
                    <div className={styles.clubAlert}>
                      <div>
                        Your {club.status !== 'publish' ? 'IGM' : 'AGM'} is
                        currently open for RSVP and nominations.
                        <br />
                        Nominations received: {nominationsCount}
                      </div>

                      <div className={styles.buttonCluster}>
                        <Button
                          level='secondary'
                          size='tiny'
                          type='button'
                          onClick={() =>
                            navigate(
                              `/events/${historyRecord.acf.event.post_name}`
                            )
                          }>
                          View event
                        </Button>
                        <Button
                          level='primary'
                          size='tiny'
                          type='button'
                          onClick={() =>
                            linkToManagementPage('run-gm', {
                              eventId: historyRecord.acf.event.ID,
                            })
                          }>
                          Start {club.status !== 'publish' ? 'IGM' : 'AGM'}
                        </Button>
                      </div>
                    </div>
                  }
                />
              )}

            {/* AGM Step 4 */}
            {(get(club, 'acf.agm_functionality', false) && historyRecord && historyRecord.acf.event.post_status === "publish" && historyRecord.acf.status === 'running' && historyRecord.acf.returning_officer && historyRecord.acf.returning_officer !== '' && historyRecord.acf.returning_officer !== null) && (
              <MessageAlert type="warn" message={(
                <div className={styles.clubAlert}>
                  <div>
                    Your {club.status !== 'publish' ? 'IGM' : 'AGM'} has started.
                  </div>

                  <div className={styles.buttonCluster}>
                    <Button
                      level='primary'
                      size='tiny'
                      type='button'
                      onClick={() =>
                        linkToManagementPage('run-gm', {
                          eventId: historyRecord.acf.event.ID,
                        })
                      }>
                      Manage {club.status !== 'publish' ? 'IGM' : 'AGM'}
                    </Button>
                  </div>
                </div>
                )}
              />
            )}

            {/* AGM Step 5 */}
            {historyRecord &&
              historyRecord.acf.event.post_status === 'publish' &&
              historyRecord.acf.status === 'pending' && (
                <MessageAlert
                  type='warn'
                  message={
                    <div className={styles.clubAlert}>
                      <div>
                        Your {club.status !== 'publish' ? 'IGM' : 'AGM'} is
                        currently being reviewed by USU Staff.
                      </div>

                      <div className={styles.buttonCluster}>
                        <Button
                          level='primary'
                          size='tiny'
                          type='button'
                          onClick={() =>
                            linkToManagementPage('run-gm', {
                              eventId: historyRecord.acf.event.ID,
                            })
                          }>
                          Revise {club.status !== 'publish' ? 'IGM' : 'AGM'}{' '}
                          submission
                        </Button>
                      </div>
                    </div>
                  }
                />
              )}

            {execDetails && execDetails.position.startsWith('Exiting ') && (
              <MessageAlert type="warn" message={`As an exiting executive of this club, your access to the club admin will be expiring after ${execDetails.expiry_date}`} />
            )}

            {clubNotifications.length > 0 && (
              <>
                <div className='flex-between'>
                  <h6>Club notices</h6>
                  <Button
                    type='span'
                    link={true}
                    onClick={() => linkToManagementPage('notices')}>
                    View All
                  </Button>
                </div>
                <div className={styles.infoBar}>
                  <InformationBar messages={clubNotifications} />
                </div>
              </>
            )}

            <div className={styles.clubManagementHeader}>
              <div className={styles.clubContent}>
                <h5>{decode(club.title.rendered)}</h5>
                <div className={styles.clubDetails}>
                  {grantValue && (
                    <div className={styles.grantDetails}>
                      <strong>Semester grant eligibility:</strong> <CurrencyFormatter amount={grantValue} /> per semester
                    </div>
                  )}
                  <ul>
                    <li>
                      <p>
                        <strong>AGM Month</strong>
                      </p>
                      <p>{club.acf.agm_month || 'Month not yet defined'}</p>
                    </li>
                    <li>
                      <p>
                        <strong>Financial Month</strong>
                      </p>
                      <p>
                        {club.acf.election_month || 'Month not yet defined'}
                      </p>
                    </li>
                    {/* <li>
                      <p>
                        <strong>Printer Code</strong>
                      </p>
                      <p>{club.acf.printer_code || 'Code not assigned'}</p>
                    </li>
                    <li>
                      <p>
                        <strong>Vendor Code</strong>
                      </p>
                      <p>{club.acf.vendor_code || 'Code not assigned'}</p>
                    </li> */}

                    <li>
                      <p>
                        <strong>Tags</strong>
                      </p>
                      {tags.length > 0 ? (
                        <p>{tags.join(', ')}</p>
                      ) : (
                        <p>No tags defined</p>
                      )}
                    </li>
                    <li>
                      <p>
                        <strong>Membership Fees</strong>
                      </p>
                      {club.acf.fees && club.acf.fees.length > 0 ? (
                        <>
                          {!club.acf.disable_free_memberships && (
                            <p>Standard: Free</p>
                          )}
                          {club.acf.fees.map((fee, feeIndex) => (
                            <p key={feeIndex}>
                              {fee.label}: ${fee.value}
                            </p>
                          ))}
                        </>
                      ) : (
                        <p>Standard: Free</p>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.clubLogo}>
                {club.acf.logo && (
                  <img src={club.acf.logo} alt={club.title.rendered} />
                )}
              </div>
            </div>

            {club.status !== 'publish' && (
              <>
                <hr />
                <div className={styles.startUpClub}>
                  <h6>Club creation information</h6>
                  <div className={styles.currentStatus}>
                    <strong>Current Status:</strong>{' '}
                    <span>
                      {club.status === 'signatures' && <>Fetching interest</>}
                      {club.status === 'compliant' && <>Awaiting approval</>}
                      {club.status === 'ign' && <>Holding IGM</>}
                      {club.status === 'engaged' && <>Under Review</>}
                    </span>
                  </div>
                  <div className={styles.statusDetail}>
                    {club.status === 'signatures' && (
                      <>
                        <p>
                          The club is currently listed on the clubs page on the
                          website under "Pending clubs" to allow USU members to
                          register their interest in your club application.
                        </p>
                        <p>
                          You have{' '}
                          <strong>
                            {
                              club.acf.application_settings
                                .accepting_signatures_period
                            }
                          </strong>{' '}
                          days from the application approval date to obtain{' '}
                          <strong>
                            {
                              club.acf.application_settings
                                .minimum_amount_of_signatures_required
                            }
                          </strong>{' '}
                          interested parties to become eligible to progress to
                          the next stage.
                        </p>
                        <p>
                          <strong>Application approval date:</strong>{' '}
                          {formatDate(
                            club.acf.application_settings.campaign_start_date,
                            0
                          )}
                        </p>
                        <p>
                          <strong>Campaign expiry date:</strong>{' '}
                          {formatDate(
                            club.acf.application_settings.campaign_start_date,
                            club.acf.application_settings
                              .accepting_signatures_period
                          )}
                        </p>
                      </>
                    )}

                    {club.status === 'compliant' && (
                      <>
                        <p>
                          Congratulations, you have reached the required amount
                          of interested members and USU staff are reviewing the
                          list.
                        </p>
                        <p>
                          Keep an eye out in your email for your approval and
                          welcome email, explaining what your next steps are to
                          progress the setup of the club.
                        </p>
                      </>
                    )}

                    {club.status === 'ign' && (
                      <>
                        <p>
                          Congratulations, the club has been given the authority
                          to setup and run an IGM to discuss the running of the
                          club and vote in members to club roles.
                        </p>
                      </>
                    )}

                    {club.status === 'engaged' && (
                      <>
                        <p>
                          The details from your IGM are being reviewed by USU
                          staff and their will be in touch soon.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            <hr />
            <div className={styles.clubManagementLinks}>
              {/* Fetching signatures & awaiting compliant approval */}
              {['signatures', 'compliant'].indexOf(club.status) > -1 && (
                <>
                  <span
                    role='presentation'
                    className={`${styles.manageNavigation} ${styles.editDetails}`}
                    onClick={() => linkToManagementPage('members')}>
                    <Icon symbol='members' />
                    View interested members
                  </span>
                </>
              )}

              {/* Fetching signatures & awaiting compliant approval */}
              {['ign'].indexOf(club.status) > -1 && (
                <>
                  <span
                    role='presentation'
                    className={`${styles.manageNavigation} ${styles.editDetails}`}
                    onClick={() => linkToManagementPage('members')}>
                    <Icon symbol='members' />
                    View members
                  </span>

                  {eventList && eventList.length > 0 ? (
                    <span
                      role='presentation'
                      className={`${
                        eventList[0].slug !== ''
                          ? styles.manageNavigation
                          : styles.staticNavigation
                      } ${styles.editDetails}`}
                      onClick={() =>
                        eventList[0].slug !== ''
                          ? linkToPageNewTab(
                              `${process.env.SITE_URL}/events/${eventList[0].slug}`
                            )
                          : {}
                      }>
                      <Icon symbol='list-edit' />
                      {eventList[0].slug !== ''
                        ? 'View IGM Event'
                        : 'IGM Event pending approval'}
                    </span>
                  ) : (
                    <span
                      role='presentation'
                      className={`${styles.manageNavigation} ${styles.editDetails}`}
                      onClick={() => linkToManagementPage('event-submission')}>
                      <Icon symbol='list-edit' />
                      Create IGM Event
                    </span>
                  )}

                  {eventList &&
                    eventList.length > 0 &&
                    eventList[0].slug !== '' && (
                      <>
                        <span
                          role='presentation'
                          className={`${styles.manageNavigation} ${styles.editDetails}`}
                          onClick={() =>
                            linkToManagementPage('event-guests', {
                              eventSlug: eventList[0].slug,
                              eventId: eventList[0].id,
                            })
                          }>
                          <Icon symbol='list-edit' />
                          Check-in IGM Attendees
                        </span>
                        <span
                          role='presentation'
                          className={`${styles.manageNavigation} ${styles.editDetails}`}
                          onClick={() =>
                            linkToManagementPage('run-gm', {
                              eventSlug: eventList[0].slug,
                              eventId: eventList[0].id,
                            })
                          }>
                          <Icon symbol='list-edit' />
                          {voteCount > 0 ? <>Finalise IGM</> : <>Manage IGM</>}
                        </span>
                      </>
                    )}
                </>
              )}

              {/* Full approved club menu */}
              {club.status === 'publish' && (
                <>
                  <span
                    role='presentation'
                    className={`${styles.manageNavigation} ${styles.editDetails}`}
                    onClick={() => linkToManagementPage('details')}>
                    <Icon symbol='list-edit' />
                    Edit club details
                  </span>

                  <span
                    role='presentation'
                    className={`${styles.manageNavigation} ${styles.clubExecs}`}
                    onClick={() => linkToManagementPage('club-execs')}>
                    <Icon symbol='members' />
                    Club Execs
                  </span>
                  <span
                    role='presentation'
                    className={`${styles.manageNavigation} ${styles.preferences}`}
                    onClick={() => linkToManagementPage('preferences')}>
                    <Icon symbol='gear' />
                    Club Preferences
                  </span>

                  <span
                    role='presentation'
                    className={`${styles.manageNavigation} ${styles.communicate}`}
                    onClick={() => linkToManagementPage('communicate')}>
                    <Icon symbol='mail' />
                    Communicate to members
                  </span>
                </>
              )}
            </div>
          </div>
          <div className={styles.subRoots}>
            {['engaged'].indexOf(club.status) === -1 && (
              <div
                className={`${styles.root} ${styles.withBg} ${styles.actionCard}`}>
                <div>
                  {memberList ? (
                    <div className={styles.displayNumberContainer}>
                      <span className={styles.displayNumber}>
                        {memberList.length} &nbsp;
                      </span>
                      {['signatures', 'compliant', 'ign'].indexOf(club.status) >
                        -1 && <strong>Registered interest</strong>}
                      {club.status === 'publish' && (
                        <strong>
                          Active member{memberList.length > 1 && 's'}
                        </strong>
                      )}
                    </div>
                  ) : (
                    'Fetching club members...'
                  )}
                </div>
                <span
                  role='presentation'
                  className={`${styles.manageNavigation}`}
                  onClick={() => linkToManagementPage('members')}>
                  See
                </span>
              </div>
            )}

            {club.status === 'publish' && (
              <div
                className={`${styles.root} ${styles.withBg} ${styles.actionCard}`}>
                <div>
                  {eventList ? (
                    <div className={styles.displayNumberContainer}>
                      <span className={styles.displayNumber}>
                        {eventList.length} &nbsp;
                      </span>
                      <strong>
                        Upcoming event{eventList.length > 1 && 's'}
                      </strong>
                    </div>
                  ) : (
                    'Fetching events...'
                  )}
                </div>
                <span
                  role='presentation'
                  className={`${styles.manageNavigation}`}
                  onClick={() => linkToManagementPage('events')}>
                  Manage
                </span>
              </div>
            )}

            {club.status === 'publish' && historyList && (
              <div
                className={`${styles.root} ${styles.withBg} ${styles.actionCard}`}>
                <div>
                  {historyList ? (
                    <div className={styles.displayNumberContainer}>
                      <span className={styles.displayNumber}>
                        {historyList.length} &nbsp;
                      </span>
                      <strong>General meetings held</strong>
                    </div>
                  ) : (
                    'Fetching general meetings...'
                  )}
                </div>
                <span
                  role='presentation'
                  className={`${styles.manageNavigation}`}
                  onClick={() => linkToManagementPage('view-gms')}>
                  See history
                </span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={styles.root}>
          {/* Fetching clubs */}
          {((isAuthed === null && club === null) ||
            (isAuthed === true && club === null)) && (
            <div>Fetching your club data</div>
          )}
          {/* No clubs found */}
          {isAuthed === true && club === false && (
            <div>
              An error occurred. Return back to{' '}
              <Link to='/account/my-usu/clubs'>
                <u>list</u>
              </Link>
            </div>
          )}
          {/* No clubs found */}
          {isAuthed === false && (
            <div>
              You are not authorised to manage this club. Return back to{' '}
              <Link to='/account/my-usu/clubs'>
                <u>list</u>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const ClubManagementOutput = ({ location }) => {
  const persistedLocation = persistLocation(location);
  return (
    <AccountPageWrapper
      metaTitle='Account - Club Management'
      bgRaw
      breadcrumbTitle={get(persistedLocation, 'state.clubTitle', '')}
      breadcrumbs={[{ link: '/account/my-usu/clubs/', label: 'My USU' }]}
      menuType='clubManage'
      location={persistedLocation}>
      <Manage location={persistedLocation} />
    </AccountPageWrapper>
  )
};

export default ClubManagementOutput;

